import React from 'react';
import { connect } from 'react-redux';
import './about.css';



const AboutSection = props => {

    const _data = props.data;

    return (
        <section data-aos="fade-up" className="aboutus section-ptb">
     
            {_data && _data.map((dt, idx) =>

                <div key={idx} className="aboutus-content mycontainer">
                    <div className="aboutus-content--img">
                        <img src={dt.imgUrl} alt="hakkımızda image" alt="Demireller yapi resmi" />
                    </div>

                    <div className="aboutus-content--text">
                        <h1 className="section-title text-left">Hakkımızda</h1>
                        <div dangerouslySetInnerHTML={{ __html: dt.descText }} />
                    </div>
                </div>
            )
            }

        </section>
    )
};

export default connect()(AboutSection);