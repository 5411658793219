import React, {useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PDFDocument from '../../components/Document/Document';
import SocialShare from '../../components/SocialShare/SocialShare';
import FeaturedCard from '../../components/FeaturedCard/FeaturedCard';
import BaseButton from '../../components/BaseButton/BaseButton';
import PageBreadCrumb from '../../components/PageBreadCrumb/PageBreadCrumb';
import Loader from '../../components/Loader/Loader';

import './productdetail.css';

const ProductDetail = props => {
    const [data, setData] = useState({});
    const [simil, setSimil] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const breadCrumbData = {};
    const socialData = {};
    socialData.url = String(window.location);
    socialData.title = "Website: ";
    socialData.size = "2.5rem";


    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "Ürün Detay | Demireller Yapı ve Tarım" ;

       const fetchData = async () => {
            const id = props.match.params.id;
            const url = "api/getproductDetails?id=" + id;
            const response = await fetch(url);
            const productDetailData = await response.json();
            setData(productDetailData[0].productDetailObj[0]);
            setSimil(productDetailData[0].productSimilar);
            setIsLoading({ isLoading: false })
        };

        fetchData();
     
    }, [props.match.params.id]);


    breadCrumbData.bgImageUrl = "";
    breadCrumbData.pageTitle = data.title;
    breadCrumbData.linkList = [
        { title: "Anasayfa", url: "/" },
        { title: "Kategoriler", url: "/productcategory/" + data.fkCategory },
        { title: "Ürün Detay", url: "/productdetail/" + data.id },

    ]

    return (
        <React.Fragment>
            <Loader isLoading={isLoading} />
            <PageBreadCrumb BreadcrumbData={breadCrumbData} />
        <div className="product-detail-container  mycontainer">
             <div className="documnet-container">
                {<PDFDocument catalog={[data.catalog]} />}
             </div>
             <div className="product-info">
                <h1> {data.title } </h1>
                <h2 className="product-info--price"> {data.price  } </h2>
                <h5 className="product-info--stock"> <strong>Stok </strong>: <span className="detail-text">  {data.stock == 'True' ? "Stokta Var" : "Stokta Yok"}  </span></h5>
                <p className="detail-text">  {data.description} </p>

                <div className="Product-share">
                    <h5><strong>Ürün Kodu </strong>: <span className="detail-text">{data.id} </span></h5>
                    <h5><strong>Etiketler </strong>: <span className="detail-text"> {data.hashtag} </span></h5>
                    <SocialShare shareData={socialData} />
                </div>
                <div className="product-detail-btn">  <BaseButton style={{ textAlign: 'center!important' }} title="İLETİŞİME GEÇ" url="/contact" />  </div>
             </div>
        </div>

        <div className="smilarproduct-container  mycontainer">
            <h1>Benzer Ürünler</h1>
            <div className="smilarproduct">
                {simil.map((dta, idx) => <FeaturedCard key={idx} cardData={dta} />)}
            </div>
        </div>
        </React.Fragment>
    )

};

export default connect()(ProductDetail);