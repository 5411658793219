import React, { Component} from 'react';

import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../store/ProductDropdown';



const ProductMenu = props => {

    return (
        <li className="first-tier--list"><a className="main-link--item" href="#">Ürünler</a>
            <ul className="first-tier-dropdown">
                {
                    props.products.map((product, index) => {
                        if (product.subCategoryMenuItems.length == 0) {
                            return (<li key={product.id}><Link onClick={props.onSubmenu} className="sub-link--item" to={`/productcategory/${product.friendlyUrl}`}>{product.categoryName}</Link></li>)
                        } else {
                            return (<li key={product.id} className="second-tier--list"><Link onClick={props.onSubmenu} className="sub-link--item" to={`/productcategory/${product.friendlyUrl}`}>{product.categoryName}</Link>
                                <ul className="second-tier--dropdown">
                                    {
                                        product.subCategoryMenuItems.map((sub, indx) => {
                                            return (<li key={indx} ><Link onClick={props.onSubmenu} className="sub-link--item" to={`/productfranchise/${product.id}/${sub.id + '/' + (sub.dealerName).replace(/ /g, '_')}`}>{sub.dealerName}</Link></li>)
                                        })
                                    }
                                </ul>
                            </li>
                            )
                        }
                    })
                }
            </ul>

        </li>
    )

};





export default connect()(ProductMenu);