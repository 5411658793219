import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import HeroSlider from '../../components/HeroSlider/HeroSlider';
import FeaturedSection from '../../components/FeaturedSection/FeaturedSection';
import CategorySection from '../../components/CategorySection/CategorySection';
import AboutSection from '../../components/AboutSection/about';
import Franchise from '../../components/FranchiseSection/FranchiseSection';
import Loader from '../../components/Loader/Loader';

const Home = props => {


    const [sliderData, setSliderData] = useState([]);
    const [aboutData, setAboutData] = useState([]);
    const [categoryData, setCategoryData] = useState([]);
    const [featuredData, setFeaturedData] = useState([]);
    const [franchiseData, setFranchiseData] = useState([]);
    const [recievedData, setRecievedData] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        fetchData();
        window.scrollTo(0, 0);
        document.title = "Anasayfa | Demireller Yapı ve Tarım";
    }, []);

    const fetchData = async () => {

        const url = "api/gethomepagedata";
        const response = await fetch(url);
        const productHomeData = await response.json();

        if (productHomeData.length > 0) {
            setSliderData(productHomeData[0].slider);
            setAboutData(productHomeData[0].aboutUs);
            setCategoryData(productHomeData[0].productCategory);
            setFeaturedData(productHomeData[0].featuredProduct);
            setFranchiseData(productHomeData[0].franchiseProduct);
            setRecievedData(true);
            setIsLoading({ isLoading: false });
        } 

    };



  return (
      <>
      <div>
        <Loader isLoading={isLoading} />
        <HeroSlider data={sliderData} />
        <AboutSection data={aboutData} />
        <CategorySection data={categoryData} />
        <FeaturedSection data={featuredData} />
        <Franchise data={franchiseData} />
       </div>
   </>
   )
};

export default connect()(Home);
