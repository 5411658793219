import React from 'react';
import { connect } from 'react-redux';
import CategoryCard from '../CategoryCard/CategoryCard';

import './categorySection.css';


const CategorySection = props => (

    <section  className="product-categories--wrapper  section-ptb">
        <h1 className="section-title text-center">Ürün Grupları</h1>
        <div className="product-categories mycontainer">
            {props.data.map((dt, idx) => <CategoryCard key={idx} data={dt} />)}
        </div>
    </section>
);

export default connect()(CategorySection);
