import React, { Component } from 'react';
import { NavLink, Link} from 'react-router-dom';
import ProductMenu from '../ProductMenu/ProductMenu';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../store/ProductDropdown';
import logo from '../../Images/yatay-logo.png';
import './NavMenu.css';

 class NavMenu extends Component {
     constructor(props) {
         super(props);
         this.toggle = this.toggle.bind(this);
         this.onSubmenuClick = this.onSubmenuClick.bind(this);
         this.state = {
             isOpen: false,
             dropdownData: []
         };

      
     }

     componentDidMount() {
         this.ensureDataFetched();
     }

    async ensureDataFetched() {
         const url = "api/getproductdropdownlinks";
         const response = await fetch(url);
         const dropdowns = await response.json();
        this.setState({ dropdownData: dropdowns});
     }


     onSubmenuClick() {
         this.setState({ isOpen: !this.state.isOpen });
     }


    toggle() {
    this.setState({ isOpen: !this.state.isOpen});
    }


  render () {
    return (

        <header className="menu-header">
            <div className="header-top">
              <span className="header-top--logo">
                    <Link to="/"> <img src={logo} alt="logo" /></Link>
              </span>
                <span className="header-top-contacts"> <span><a href="tel:+905335106234">  <i className="fa fa-phone"></i> +905335106234 </a></span> <span><a href="mailto:info@demirelleryapi.com"><i className="fa fa-envelope"></i> info@demirelleryapi.com </a></span>
                </span>
                <svg onClick={() => { this.toggle() }} className="menu-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M2 6h20v3H2zm0 5h20v3H2zm0 5h20v3H2z" /></svg>
            </div>
            <nav id="mainMenu" className={  this.state.isOpen ? 'show' : ''}>
                <ul>
                    <li><NavLink exact activeClassName="activeLink" onClick={() => { this.toggle() }} className="main-link--item" to="/">ANASAYFA</NavLink></li>
                    <ProductMenu products={this.state.dropdownData} onSubmenu={this.onSubmenuClick}  />
                    <li><NavLink activeClassName="activeLink" onClick={() => { this.toggle() }} className="main-link--item" to="/corporate">KURUMSAL</NavLink></li>
                    <li><NavLink activeClassName="activeLink" onClick={() => { this.toggle() }} className="main-link--item" to="/dealer">BAYİLİKLERİMİZ</NavLink></li>
                    {/*  <li><NavLink activeClassName="activeLink" onClick={() => { this.toggle() }} className="main-link--item" to="/gallery">Galeriler</NavLink></li>*/}
                    <li><NavLink activeClassName="activeLink" onClick={() => { this.toggle() }} className="main-link--item" to="/contact">İLETİŞİM</NavLink></li>
                    </ul>
                </nav>
             </header>

    );
  }
}

export default connect(
    state => state.dropdowns,
    dispatch => bindActionCreators(actionCreators, dispatch),
)(NavMenu);
