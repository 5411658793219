import React, { useEffect, useState, useRef }  from 'react';
import { connect } from 'react-redux';
import PageBreadCrumb from '../../components/PageBreadCrumb/PageBreadCrumb';
import Loader from '../../components/Loader/Loader';
import contact_bg from '../../Images/contact_bg.jpg';

import './contact.css';


const breadCrumbData = {
    bgImageUrl: contact_bg,
    pageTitle : "İletişim",
    linkList :[
        { title: "Anasayfa", url: "/" },
        { title: "İletişim", url: "/contact" }
    ]
};

const Contact = props => {

    const [isLoading, setIsLoading] = useState(true);
    const [Ad, setName] = useState("");
    const [Soyadı, setSurname] = useState("");
    const [Cep_telefonu, setTelephone] = useState("");
    const [Eposta, setEmial] = useState("");
    const [Mesaj, setMessege] = useState("");

    const btnSubmit = useRef(null);
    const msgAlert = useRef(null);



    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "İletişim | Demireller Yapı ve Tarım";
        setIsLoading({ isLoading: false });
    }, []);



    const onFormSubmit = (e) => {
        e.preventDefault();
        btnSubmit.current.value = 'Lütfen Bekleyin...';
        btnSubmit.current.disabled = true;

        fetch("https://formsubmit.co/ajax/info@demirelleryapi.com", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                Ad,
                Soyadı,
                Eposta,
                Cep_telefonu,
                Mesaj,
                _subject: "demirelleryapi.com",
                _template: "table",
            })
        })
            .then(response => response.json())
            .then(data => {
                if (data.success == 'true') {

                    setName("");
                    setSurname("");
                    setTelephone("");
                    setEmial("");
                    setMessege("");
                    btnSubmit.current.value = 'Gönder';
                    btnSubmit.current.disabled = false;
                    msgAlert.current.style.display = "block";
                }
            })
            .catch(error => console.log(error));
    }


    return (
        <div className="contactus-main-wrapper">
            <Loader isLoading={isLoading} />
            <PageBreadCrumb BreadcrumbData={breadCrumbData} />

            <section className="contact_wrapper mycontainer">
                <div className="contact_info">
                    <h3 className="title">
                        İletişim Bilgileri
                    </h3>
                    <ul className="icons_wrapp">
                        <li>
                            <div className="icon">
                                <span className="material-icons-outlined"> place </span>
                            </div>
                            <div className="text">
                                Ilıca Mah. Antalya Blv. No:79/3 Manavgat-Antalya
                            </div>
                        </li>
                        <li>
                            <div className="icon">
                                <span className="material-icons-outlined"> mail_outline </span>
                            </div>
                            <div className="text">
                                info@demirelleryapi.com
                            </div>
                        </li>
                        <li>
                            <div className="icon">
                                <span className="material-icons-outlined"> phone_in_talk </span>
                            </div>
                            <div className="text">
                                02424746766 <br />
                                05335106234
                            </div>
                        </li>
                    </ul>

                    <div className="socialmedia-icons">
                        <ul className="footer-socialmedia-list">
                            <li className="facebook"><a href="https://www.facebook.com/demirelleryapi/" rel="noreferrer" target='_blank'><i className="fa fa-facebook-official" style={{ color: 'white', fontSize: '2rem' }} aria-hidden="true"></i></a></li>
                            {/*<li className="twitter"><a href="https://twitter.com/demirelleryapi/" rel="noreferrer" target='_blank'><i className="fa fa-twitter-square" style={{ color: 'white', fontSize: '2rem' }} aria-hidden="true"></i></a></li>*/}
                            <li className="instagram"><a href="https://www.instegram.com/demirelleryapi" rel="noreferrer" target='_blank'><i className="fa fa-instagram" style={{ color: 'white', fontSize: '2rem' }} aria-hidden="true"></i></a></li>
                            {/*<li className="youtube"><a href="#"><i className="fa fa-linkedin-square" style={{ color: 'white', fontSize: '2rem' }} aria-hidden="true"></i></a></li>*/}
                        </ul>
                    </div>
                </div>

                <div className="contact_msg">
                    <h3 className="title">
                        Mesaj Gönderiniz
                    </h3>

                    <form className="form_fild" onSubmit={onFormSubmit}>
                        <div className="input_group w_50">
                            <input type="text" className="input"
                                value={Ad}
                                onChange={(e) => setName(e.target.value)}
                                required />


                            <label className="placeholder">Ad</label>
                        </div>

                        <div className="input_group w_50">
                            <input type="text" className="input"
                                value={Soyadı}
                                onChange={(e) => setSurname(e.target.value)}
                                required />

                            <label className="placeholder">Soyadı </label>
                        </div>

                        <div className="input_group w_50">

                            <input type="text" className="input"

                                value={Eposta}
                                onChange={(e) => setEmial(e.target.value)}
                                required />
                            <label className="placeholder">E-posta Adres</label>
                        </div>

                        <div className="input_group w_50">
                            <input type="tel" className="input" value={Cep_telefonu}
                                onChange={(e) => setTelephone(e.target.value)} required />
                            <label className="placeholder">Cep Telefonu</label>
                        </div>

                        <div className="input_group w_100">
                            <textarea className="input input_textarea " value={Mesaj}
                                onChange={(e) => setMessege(e.target.value)} rows="6" required></textarea>
                            <label className="placeholder textarea">İsteğiniz burada yazınız...</label>
                        </div>

                        <div className="input_group">
                            <input type="submit" ref={btnSubmit} className="btn" value="Gönder" />
                        </div>
                        <div className="alert alert-success" ref={msgAlert} style={{ display: 'none' }}> Mesajiniz başarılı ile gönderilimiştir. </div>
                    </form>
                </div>
            </section>
            <div className="map-container">
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12776.397908073217!2d31.3649826!3d36.8161361!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xd6c0b7ed256153c7!2zRGVtaXJlbGxlciBZYXDEsSAmIFRhcsSxbQ!5e0!3m2!1sen!2str!4v1633959567624!5m2!1sen!2str" width="600" height="450" style={{ border: '0', }} allowFullScreen="" loading="lazy"></iframe>
            </div>
        </div>

    );

}



export default connect()(Contact);