import React from 'react';
import { connect } from 'react-redux';
import './about.css';



const AboutCompany = props => {


    const _data = props.aboutData.aboutData;



    return (
        <section className="aboutus">

            {_data && _data.map((dt, idx) =>

                <div key={idx} className="aboutus-content mycontainer">
                    <div className="aboutus-content--img">
                        <img src={dt.imgUrl} alt="hakkımızda image" />
                    </div>

                    <div className="aboutus-content--text">
                        <h1 className="section-title text-left">Hakkımızda</h1>
                        <div dangerouslySetInnerHTML={{ __html: dt.descText }} />
                    </div>
                </div>
            )
            }

        </section>
    )
};

export default connect()(AboutCompany);