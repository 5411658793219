import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import './CategoryCard.css';
import defaultImg from '../../Images/placeholder_img.png';

const CategoryCard = props => {

    const { categoryId, categoryName, mobileImageUrl, description, franchiseList, friendlyUrl } = props.data;


    return (

        <div className="mycard" data-aos="fade-up">
            <Link className="mycard-link" to={`/productcategory/${categoryId}`}></Link>
            <div className="mycard-img-box">
                <img className="mycard-img" src={`${mobileImageUrl.length == 0 ? defaultImg : mobileImageUrl}`} alt={categoryName} />
            </div>
            <div className="mycard-body">
                <h1>{categoryName}</h1>
                <div className="franchise">  {franchiseList.map((fan, idx) => <h5 key={idx}>{fan.name}  </h5>)} </div>
                <p>{description}</p>
            </div>
            <Link className="products-link" to={`/productcategory/${friendlyUrl}`}>Ürünler</Link>
        </div>

    );

}

export default connect()(CategoryCard);
