
import React from 'react';
import { connect } from 'react-redux';
import './Reference.css';

import ref_bg from '../../Images/reference_bg.jpg';

const Reference = props => {

   const _data = props.refData.referenceData;

    return (

        <div className="reference-wrapper">
            {_data  && _data.length > 0 && (<h1 className="section-title text-center">Referanslar</h1>)}
            <div className="reference-container mycontainer">
                {
                    _data && _data.map((dt, idx) =>
                        <div data-aos="flip-left" className="referenceCard" key={idx}>
                            <img className="refcard-img" src={dt.imageUrl} alt={dt.title} />
                            <div className="referenceCard-footer">
                                <h1>{dt.title}</h1>
                                <p>{dt.description}</p>
                            </div>
                      </div>
                   )
                }
            </div>
        </div>
    )
};



export default connect()(Reference);