import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Loader from '../../components/Loader/Loader';
import PageBreadCrumb from '../../components/PageBreadCrumb/PageBreadCrumb';
import Pagination from '../../components/Pagination/Pagination';
import FeaturedCard from '../../components/FeaturedCard/FeaturedCard';
import VerticalNavMenu from '../../components/VerticalNavMenu/VerticalNavMenu';
import './ProductFranchiseCategory.css';
import '../ProductCategory/productcategory.css';
import brochure from '../../Images/brochure.jpg';

const ProductFranchiseCategory = props => {

    const [isLoading, setIsLoading] = useState(true);
    const [product, setProduct] = useState([]);
    const [franchise, setFranchise] = useState([]);


    const [dropdownData, setDropdownData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);
    const [isSuccess, setIsSuccess] = useState(false);




    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "Bayilikler | Demireller Yapı ve Tarım";

        const fetchData = async () => {
             
                const id = props.match.params.id;
                const cat = props.match.params.cat;
                const url = "api/getproductsfranchisebyid?cat=" + cat +"&id="+id;
                const response = await fetch(url);
                const result = await response.json();
                if (result.status != 404) {
                    setProduct(result[0].products);
                    setFranchise(result[0].franchise);
                    setIsLoading({ isLoading: false });
                    result[0].products.length > 0 ? setIsSuccess(true) : setIsSuccess(false);
                } else {
                    setIsSuccess(false);
                }
        }
        const ensureDataFetched = async () => {
            const id = props.match.params.id;
            const url = "api/getproductsbycategoryid?id=" + id;
            const response = await fetch(url);
            const categories = await response.json();

            if (categories.status != 404) {
                setProduct(categories);
                setIsLoading({ isLoading: false });
                setIsSuccess(true);
            } else {
                setIsSuccess(false);
            }

        }
        const ensureMenuDataFetched = async () => {
            const url = "api/getproductdropdownlinks";
            const response = await fetch(url);
            const dropdowns = await response.json();
            setDropdownData(dropdowns);
            setIsLoading({ isLoading: false });
        }

        if ((props.match.path).includes('/productfranchise/'))
          {fetchData();} else { ensureDataFetched();}
          ensureMenuDataFetched();


    }, [props.match.params.id])

    const breadCrumbData = {
        bgImageUrl: brochure,
        pageTitle: "Bayiliklerimiz",
        linkList: [
            { title: "Anasayfa", url: "/" },
            { title: "Bayiliklerimiz", url: "/productfranchise/" + props.match.params.id }
        ]

    }


    // Get current posts
    const indexOfLastPost = currentPage * itemsPerPage;
    const indexOfFirstPost = indexOfLastPost - itemsPerPage;
    const currentItems = product.slice(indexOfFirstPost, indexOfLastPost);

    // Change page
    const paginate = pageNumber => setCurrentPage(pageNumber);
    return (

        <div className="productcategory-container">
             <Loader isLoading={isLoading} />
             <PageBreadCrumb BreadcrumbData={breadCrumbData} />
             
             <div className="productcategory-content  mycontainer">
                <div className="category-menu">
                    <VerticalNavMenu type="dealers" data={dropdownData} />
                </div>
                {isSuccess && (<div className="category-products">
                    {currentItems.map((data, indx) => <FeaturedCard key={indx} cardData={data} />)}</div>)
                }
                {!isSuccess && (<div className="category-products product-notfound">Kategori ile ilgili ürün bulunamadı </div>)}

                <Pagination
                    itemsPerPage={itemsPerPage}
                    totalItems={product.length}
                    paginate={paginate}
                />
             </div>
        </div>
    );

}



export default connect()(ProductFranchiseCategory);