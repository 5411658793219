
import React, {useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { actionCreators } from '../../store/ProductCategory';
import * as ProductCategoryStore from '../../store/ProductCategory';
import ProductMenu from '../../components/NavMenu/NavMenu';
import FeaturedCard from '../../components/FeaturedCard/FeaturedCard';
import PageBreadCrumb from '../../components/PageBreadCrumb/PageBreadCrumb';
import Pagination from '../../components/Pagination/Pagination';
import Loader from '../../components/Loader/Loader';
import VerticalNavMenu from '../../components/VerticalNavMenu/VerticalNavMenu';
import brochure from '../../Images/brochure.jpg';
import './productcategory.css';


const ProductCategory = (props) => {

    const [categoryData, setCategoryData] = useState([]);
    const [dropdownData, setDropdownData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);
    const [isSuccess, setIsSuccess] = useState(false);

    const breadCrumbData = {
        bgImageUrl: brochure,
        pageTitle: "Kategoriler",
        linkList: [
            { title: "Anasayfa", url: "/" },
            { title: "Kategoriler", url: "/" },
        ]

    }

    useEffect(() => {
        document.title = "Ürün Kategoriler | Demireller Yapı ve Tarım";
        window.scrollTo(0, 0);

        const ensureDataFetched = async () => {
            const id = props.match.params.id;
            const url = "api/getproductsbycategoryid?id=" + id;
            const response = await fetch(url);
            const categories = await response.json();

            if (categories.status != 404) {
                setCategoryData(categories);
                setIsLoading({ isLoading: false });
                setIsSuccess(true);
            } else {
                setIsSuccess(false);
            }

        }
        const ensureMenuDataFetched = async () => {
            const url = "api/getproductdropdownlinks";
            const response = await fetch(url);
            const dropdowns = await response.json();
            setDropdownData(dropdowns);
            setIsLoading({ isLoading: false });
        }

        ensureDataFetched();
        ensureMenuDataFetched();
 
    }, [props.match.params.id])




    // Get current posts
    const indexOfLastPost = currentPage * itemsPerPage;
    const indexOfFirstPost = indexOfLastPost - itemsPerPage;
    const currentItems = categoryData.slice(indexOfFirstPost, indexOfLastPost);

    // Change page
    const paginate = pageNumber => setCurrentPage(pageNumber);

    return (
        <div className="productcategory-container">

            <Loader isLoading={isLoading} />
            <PageBreadCrumb BreadcrumbData={breadCrumbData} />

            <div className="productcategory-content  mycontainer">
                <div className="category-menu">
                    <VerticalNavMenu type="category" data={dropdownData}/>
                </div>
                {isSuccess && (<div className="category-products">
                  { currentItems.map((data, indx) => <FeaturedCard key={indx} cardData={data} />) }
                </div>)}
                {!isSuccess && (<div className="category-products product-notfound">Kategori ile ilgili ürün bulunamadı </div>)}

                <Pagination
                    itemsPerPage={itemsPerPage}
                    totalItems={categoryData.length}
                    paginate={paginate}
                />
            </div>
        </div>
    );
}








export default connect(
    state => state.productCategories,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(ProductCategory);
