
import React from 'react';
import { Route } from 'react-router';
import { BrowserRouter as Router, Switch } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import Layout from './components/Layout/Layout';
import Home from './Views/Home/Home';
import Corporate from './Views/Corporate/Corporate';
import Contact from './Views/Contact/Contact';
//import Gallery from './Views/Gallery/Gallery';
import Dealers from './Views/Dealers/Dealers';
import ProductCategory from './Views/ProductCategory/ProductCategory';
import ProductDetail from './Views/ProductDetail/ProductDetail';
import ProductFranchiseCategory from './Views/ProductFranchiseCategory/ProductFranchiseCategory';
import NotFound from './Views/NotFound/NotFound';
import Counter from './components/Counter';
import FetchData from './components/FetchData';
import './custom.css';

AOS.init();


export default () => (
    <Layout>

        <Switch>
        <Route exact path='/' component={Home} />
        <Route path='/counter' component={Counter} />
        <Route path='/fetch-data/:startDateIndex?' component={FetchData} />
        <Route path='/corporate' component={Corporate} />
        <Route path='/contact' component={Contact} />
  {/*      <Route path='/gallery' component={Gallery} />*/}
        <Route path='/dealer' component={Dealers} />
        <Route path='/productcategory/:id' component={ProductCategory} />
        <Route path='/productfranchise/:cat/:id' component={ProductFranchiseCategory} />
        <Route path='/productdetail/:id' component={ProductDetail} />
        <Route path='*' component={NotFound} />
       </Switch>
    </Layout>
);
