
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import './basebutton.css';

const BaseButton = props => (
    <Link className="mybtn mybtn-default" to={props.url}  title="Detay" dangerouslySetInnerHTML={{ __html: props.title }}></Link>
);

export default connect()(BaseButton);