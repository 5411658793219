import React, { useEffect, useState} from 'react';
import { connect } from 'react-redux';
import  './footer.css';
import { Link } from 'react-router-dom';
import logo from '../../Images/yatay-logo.png';

const Footer = props => {

    const [data , setData] = useState([]);
    const [about , setAbout] = useState("");

    useEffect(() => {
        fetchCategory();
    },[])

    const fetchCategory = async () => {

        const url = "api/getfootercategory";
        const response = await fetch(url);
        const productData = await response.json();
        if (productData.length > 0) {
            setAbout(productData[0].aboutData[0].descText);
            setData(productData[0].productCategoryData);
        } else {
            console.log(productData);
        }
    };



    return (
        <section className="footer">
            <div className="footer-content mycontainer">
                <div className="footer-content-left">
                    <div className="company-info">
                        <img src={logo} alt="Demireller logo" />
                        <p className="about-company" dangerouslySetInnerHTML={{ __html: about }} />
                        <div className="socialmedia-content">
                            <h5 className="footer-link-title">Sosyal Medya</h5>
                            <div className="socialmedia-icons">
                                <ul className="footer-socialmedia-list">
                                    <li className="facebook"><a href="https://www.facebook.com/demirelleryapi/" rel="noreferrer" target='_blank'><i className="fa fa-facebook-official" aria-hidden="true"></i></a></li>
                                    <li className="instagram"><a href="https://www.instegram.com/demirelleryapi" rel="noreferrer" target='_blank'><i className="fa fa-instagram" aria-hidden="true"></i></a></li>
                                   {/* <li className="youtube"><a href="#" target='_blank'><i className="fa fa-linkedin-square" aria-hidden="true"></i></a></li>*/}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="company-links">
                        <div className="company-links--fast">
                            <h5 className="footer-link-title">Hızlı Erişimi</h5>
                            <ul className="footer-list">
                                <li><Link to="/"> Anasayfa</Link></li>
                                <li><Link to="/corporate">  Kurumsal </Link></li>
                                <li><Link to="/dealer"> Bayiliklerimiz </Link></li>
                                <li><Link to="/contact">İletişim </Link></li>
                            </ul>
                        </div>
                        <div className="company-links--kategories">
                            <h5 className="footer-link-title">Ürünler Kategoriler</h5>
                            <ul className="footer-list">
                                {data && data.map((dt, idx) =>
                                    <li key={idx}><Link to={`/productcategory/${dt.friendlyUrl}`}> {dt.categoryName} </Link></li>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="footer-content-right">

                    <h4 className="footer-link-title1">İletişim Bilgileri</h4>
                    <h5 className="footer-link-title">Bize Ulaşın</h5>

                    <ul className="footer-content-right--list">
                        <li><i className="fa fa-map-marker" aria-hidden="true"></i>  <span>
                            Ilıca Mah. Antalya Blv. No:79/3 Manavgat-Antalya</span></li>
                        <li><i className="fa fa-phone" aria-hidden="true"></i> <span> 02424746766</span></li>
                        <li><i className="fa fa-phone" aria-hidden="true"></i> <span>05335106234</span></li>
                        <li> <i className="fa fa-envelope"></i> <span> info@demirelleryapi.com</span></li>
                    </ul>
                    <h5 className="footer-link-title">Çalışma Saatleri</h5>
                    <p>Hergün 07:30-18:00</p>
                </div>
            </div>
            <footer>
                <p className="mycontainer">Copyright © 2021. Her Hakkı Saklıdır.</p>
            </footer>
        </section>
    );


}
 


export default connect()(Footer);