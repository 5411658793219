import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import PageBreadCrumb from '../../components/PageBreadCrumb/PageBreadCrumb';
import FranchiseCard from '../../components/FranchiseCard/FranchiseCard';
import Loader from '../../components/Loader/Loader';
import contact_bg from '../../Images/contact_bg.jpg';

import './Dealers.css';



const breadCrumbData = {
    bgImageUrl: contact_bg,
    pageTitle: "Bayiliklerimiz",
    linkList: [
        { title: "Anasayfa", url: "/" },
        { title: "Bayiliklerimiz", url: "/dealer" }
    ]
};

const Contact = props => {

    const [isLoading, setIsLoading] = useState(true);
    const [dealer, setDealer] = useState([]);




    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "Bayiliklerimiz | Demireller Yapı ve Tarım";
        setIsLoading({ isLoading: false });

            const fetchData = async () => {
            const url = "api/getfranchiseList";
            const response = await fetch(url);
            const dealerData = await response.json();

                console.log(dealerData);
            if (dealerData.length > 0) {
                setDealer(dealerData);
            } else {
                console.log(dealerData);
            }
        };

        fetchData();
    }, []);








    return (
        <div className="contactus-main-wrapper">
            <Loader isLoading={isLoading} />
            <PageBreadCrumb BreadcrumbData={breadCrumbData} />
            <section className="dealer_wrapper mycontainer">

                {
                    dealer.map((data, indx) => <FranchiseCard key={indx} cardData={data} />)
                }

            </section>

        </div>

    );

}



export default connect()(Contact);