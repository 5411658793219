import React from 'react';
import { connect } from 'react-redux';
import './floatingcontacticons.css';

const FloatingContactIcons = props => (
    <div className="floatingicons-container">
        <ul>
            <li> <a href="https://api.whatsapp.com/send?phone=+905335106234" className="ft-whatsapp"> <i className="fa fa-whatsapp" aria-hidden="true"></i></a> </li>
            <li> <a href="tel:+905335106234" className="ft-phone" ><i className="fa fa-phone" aria-hidden="true"></i></a> </li>
            {/*<li> <a href="https://www.instagram.com/?hl=tr" class="ft-instagram" ><i class="fa fa-instagram" aria-hidden="true"></i></a> </li>*/}
        </ul>
    </div>
);

export default connect()(FloatingContactIcons);
