import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import AboutCompany from '../../components/AboutSection/AboutCompany';
import Reference from '../../components/Reference/Reference';
import PageBreadCrumb from '../../components/PageBreadCrumb/PageBreadCrumb';
import Loader from '../../components/Loader/Loader';
import org_bg from '../../Images/organization_bg.jpg';

const Corporate = () => {

    const [aboutData, setAboutData] = useState([]);
    const [referenceData, setReferenceData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);


    useEffect(() => {
        fetchData();
        window.scrollTo(0, 0);
        document.title = "Kurumsal | Hakkımızda ve Referanslar";
    }, [])


    const breadCrumbData = {
        bgImageUrl: org_bg,
        pageTitle: "Kurumsal",
        linkList: [
            { title: "Anasayfa", url: "/" },
            { title: "Kurumsal", url: "/corporate" }
        ]
    };


    const fetchData = async () => {
         const url = "/api/getcorporateinfo";
         const response = await fetch(url);
         const corporateinfoData = await response.json();
      
        setAboutData({ aboutData: corporateinfoData[0].aboutData });
        setReferenceData({ referenceData: corporateinfoData[0].productReference });
        setIsLoading({ isLoading: false });
    };

    return (
        <div>
            <Loader isLoading={isLoading} />
            <PageBreadCrumb BreadcrumbData={breadCrumbData} />
            <div className="mycontainer">
                <AboutCompany aboutData={aboutData} />
                <Reference refData={referenceData} />
            </div>
        </div>
    );
}





export default connect()(Corporate);